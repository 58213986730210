<template>
  <a-form
    layout="vertical"
    ref="formRef"
    name="update_user_role"
    class="user-form"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="col-sm-12">
        <a-form-item label="Select Role" name="role">
          <a-select
            class="w-100"
            show-search
            v-model:value="role"
            placeholder="Select Role"
            :options="filteredRoles"
          ></a-select>
        </a-form-item>
      </div>
    </div>

    <div class="text-right">
      <a-button @click="onCancel()">
        Cancel
      </a-button>
      <a-button
        type="primary"
        html-type="submit"
        class="ml-2"
        :loading="loading"
        @click="onSubmit"
      >
        Save
      </a-button>
    </div>
  </a-form>
</template>
<script>
import UserServices from 'src/services/user';

export default {
  props: ['roles', 'email', 'currentRole'],
  emits: ['close', 'roleUpdated'],
  inject: ['toast'],
  data() {
    return {
      loading: false,
      role: this.currentRole,
      filteredRoles: null
    };
  },
  mounted() {
    this.filteredRoles = this.roles.filter(obj => obj.label !== 'admin');
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const payload = {
        role: this.role
      };
      const [error, data] = await UserServices.updateUser(this.email, payload);
      if (error) {
        this.toast.error(`Failed to switch user's role`);
        return;
      }
      this.toast.success('Role switched successfully');
      this.loading = false;
      this.$emit('roleUpdated');
    },
    onCancel() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped></style>
