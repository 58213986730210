<template>
  <a-form
    layout="vertical"
    :model="form"
    name="add_user"
    :rules="rules"
    class="user-form"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      </div>
      <div class="col-sm-5">
        <a-form-item label="First Name" name="firstName">
          <a-input
            v-model:value="form.firstName"
            placeholder="Enter First Name"
          >
          </a-input>
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.firstName.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Last Name" name="lastName">
          <a-input v-model:value="form.lastName" placeholder="Enter Last Name">
          </a-input>
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.lastName.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-email-2-at2"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Email address" name="email" autocomplete="off">
          <a-input v-model:value="form.email" placeholder="Enter email">
          </a-input>
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.email.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Role" name="role">
          <a-select
            class="w-100"
            v-model:value="form.role"
            placeholder="Select Role"
            :options="options"
          ></a-select>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-globe"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Organization" name="organization">
          <a-select
            class="w-100"
            show-search
            v-model:value="form.organization"
            placeholder="Select Organization"
            :options="organizations"
          ></a-select>
        </a-form-item>
      </div>
    </div>

    <div class="row mb-4 mt-3">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user"></div>
      </div>
      <div class="col-sm-11">
        <a-radio-group v-model:value="this.form.user" name="userType">
          <a-radio value="sso">Azure/Google SSO User</a-radio>
          <a-radio value="retro">Retro IAM User</a-radio>
        </a-radio-group>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-fingerprint"></div>
      </div>
      <div class="col-sm-5">
        <a-form-item label="Password" name="password" autocomplete="off">
          <a-input-password
            v-model:value="form.password"
            placeholder="Password"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.password.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Confirm Password" name="confirmPassword">
          <a-input
            v-model:value="form.confirmPassword"
            placeholder="Confirm Password"
            type="password"
            @change="checkPassword"
          />
          <p v-if="!isPasswordSame && form.confirmPassword">
            The value must be equal to the other value
          </p>
        </a-form-item>
      </div>
    </div> -->

    <div class="buttons-w">
      <a-button
        type="primary"
        size="large"
        html-type="submit"
        :loading="isLoading"
        style="margin-left: 120px"
      >
        Create
      </a-button>
      <a-button style="margin-left: 20px" @click="onCancel" size="large">
        <span>Cancel</span>
      </a-button>
    </div>
  </a-form>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
import Spinner from '@/components/shared/Components/Spinner';
import useVuelidate from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { roles } from 'src/config/roles-config';

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z0-9]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export default {
  props: ['roles', 'isLoading', 'organizations'],
  emits: ['addUser', 'cancel'],
  setup() {
    const v$ = useVuelidate();
    async function validUserName(_, name) {
      let validNamePattern = new RegExp("^[a-zA-Z0-9]+(?:[-'\\s][a-zA-Z]+)*$");
      if (validNamePattern.test(name)) {
        return Promise.resolve();
      }
      return Promise.reject(
        'Invalid Name. Valid name only contain letters, dashes (-) and spaces'
      );
    }
    const rules = {
      firstName: [
        { required: true, validator: validUserName, trigger: 'submit' },
      ],
      lastName: [
        { required: true, validator: validUserName, trigger: 'submit' },
      ],
      email: [{ required: true, type: 'email', trigger: 'submit' }],
      password: [{ required: true, min: 6, trigger: 'submit' }],
      confirmPassword: [{ required: true, trigger: 'submit' }],
    };
    return { rules, v$ };
  },
  components: {
    SpinnerComponent: Spinner,
  },
  data() {
    return {
      isPasswordSame: true,
      options: [],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        position: '-',
        role: '',
        organization: null,
        user: 'retro',
        // isValid: true,
      },
    };
  },
  mounted() {
    this.options = this.roles
      .filter(
        (role) =>
          ![
            roles.admin,
            roles.labeler,
            roles.analytics_user,
            // roles.workstation_user,
            roles.org_user,
          ].includes(role.label)
      )

      .map((el) => ({
        value: el.value,
        label: el.label,
      }));
    console.log('options --> ', this.options);

    this.form.role = this.roles.find((r) => r.label === roles.user).value;
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
          name_validation: {
            $validator: validName,
            $message:
              'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
          },
        },
        lastName: {
          required,
          name_validation: {
            $validator: validName,
            $message:
              'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
          },
        },
        email: { required, email },
        password: { required, min: minLength(6) },
        confirmPassword: { required },
        role: { required },
      },
    };
  },
  methods: {
    checkPassword() {
      if (this.form.password != this.form.confirmPassword) {
        this.isPasswordSame = false;
      } else {
        this.isPasswordSame = true;
      }
    },
    selectedRole(roleId) {
      this.form.role = roleId;
    },
    async onSubmit() {
      const data = {
        email: this.form.email,
        username: this.form.firstName,
        lastname: this.form.lastName,
        position: '-',
        is_active: true,
        role: this.form.role,
        organization: this.form.organization,
        is_sso: this.form.user == 'sso' ? true : false,
      };
      this.$emit('addUser', data);
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
}
</style>
